<template>
  <div
    id="contents"
    :class="{
      management: managementMode && tabIndex != 2,
      machine_management: tabIndex == 0 || tabIndex == 1,
      operation_info: tabIndex == 0,
      parts_replace_manage: tabIndex == 1,
      parts_replace_monitor: tabIndex == 2,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <AsideSelectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToLifetimeManagementPage'"
          :options="asideLists"
        />
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <a
            @click.prevent="ToggleManagementMode()"
            :class="managementMode ? 'btn_admin on' : 'btn_admin'"
            v-if="isPermissionOn && tabIndex != 2"
            href="#"
            >관리</a
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a href="#" @click.prevent="SetOpenTabIndex(index)">{{
              asideList.title
            }}</a>
          </li>
        </ul>
      </div>
      <OperationInfoForm v-if="tabIndex == 0" />
      <PartsReplaceManagementForm v-if="tabIndex == 1" />
      <PartsReplaceMonitoringForm v-if="tabIndex == 2" />
    </div>
  </div>
</template>

<script>
import OperationInfoForm from '@/views/forms/Machine/Lifetime/OperationInfoForm.vue';
import PartsReplaceManagementForm from '@/views/forms/Machine/Lifetime/PartsReplaceManagementForm.vue';
import PartsReplaceMonitoringForm from '@/views/forms/Machine/Lifetime/PartsReplaceMonitoringForm.vue';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    AsideSelectric,
    OperationInfoForm,
    PartsReplaceManagementForm,
    PartsReplaceMonitoringForm,
  },
  data() {
    return {
      asideLists: [
        { title: '가동정보', name: 'operation_info' },
        { title: '부품 교체관리', name: 'parts_replacement_management' },
        {
          title: '부품 교체 모니터링',
          name: 'parts_replacement_monitoring',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromLifetimeManagementPage',
      tabIndex: 'getOpenTabIndexFromLifetimeManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      ToggleManagementMode: 'toggleManagementModeToLifetimeManagementPage',
      SetOpenTabIndex: 'setOpenTabIndexToLifetimeManagementPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitLifetimManagementPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style></style>
