<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <div class="input_search">
            <input
              type="text"
              :value="searchText"
              placeholder="부품 검색"
              @input="setSearchText($event)"
            />
          </div>
          <div class="input_search">
            <input
              type="text"
              :value="locationText"
              placeholder="연동설비 검색"
              @input="setLocationText($event)"
            />
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filteredReplaceLists.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>부품명</th>
              <th>연동설비명</th>
              <th>설비기기위치</th>
              <th>교체&점검 잔여시간</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="part in filteredReplaceLists" :key="part.id">
              <td>
                {{ part.name }}
              </td>
              <td>
                {{ part.machine_name }}
              </td>
              <td>
                {{ part.machine_location }}
              </td>
              <td>{{ remainTime(part) }}</td>
              <td>
                <button
                  type="button"
                  class="btn_tbl"
                  @click="replacePart(part)"
                  :disabled="remainTime(part) == part.life_time"
                >
                  교체완료
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      searchText: '',
      locationText: '',
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getSelectedIndexFrompartsReplaeManagementForm',
      selectedIndex: 'getSelectedIndexFrompartsReplaeManagementForm',
      machineOptions: 'getMachinesForSelectric',
      userId: 'getUserId',
      users: 'getUsersFromUserPage',
      machines: 'getMachines',
      partsList: 'getPartsListFrompartsReplaeManagementForm',
      replaceList: 'getReplaceListFrompartsReplaeManagementForm',
      filteredReplaceList:
        'getFilteredReplaceListFrompartsReplaeManagementForm',
    }),
    filteredReplaceLists() {
      if (this.filteredReplaceList !== undefined) {
        const Hangul = require('hangul-js');
        return this.filteredReplaceList
          .filter(
            x =>
              x.name.includes(this.searchText) ||
              Hangul.d(x.name, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText),
          )
          .filter(
            x =>
              x.machine_name.includes(this.locationText) ||
              Hangul.d(x.machine_name, true)
                .map(x => x[0])
                .join('')
                .includes(this.locationText),
          );
      } else {
        return [];
      }
    },
  },
  methods: {
    setSearchText(e) {
      this.searchText = e.target.value;
    },
    setLocationText(e) {
      this.locationText = e.target.value;
    },
    remainTime(part) {
      if (part.change_dt == null || part.life_time == null)
        return part.life_time;
      let change_dt = new Date(part.change_dt);
      let date = new Date(Date.now());
      return (
        part.life_time -
        (date.getTime() - change_dt.getTime()) / (1000 * 60 * 60)
      ).toFixed(0);
    },
    async replacePart(part) {
      this.$store.dispatch('REPLACE_PART_COMPLETE', {
        part_id: part.part_id,
        r_part_machine_id: part.id,
        user_id: this.users.filter(x => x.account == this.userId)[0].id,
      });
      await this.$store
        .dispatch('FETCH_PARTS_REPLACE', this.machines)
        .then(async () => {
          this.openOneButtonModal('교체 완료', '교체를 완료하였습니다.');
          await this.$store.commit(
            'setFilteredReplaceListToReplaceMnanagementPage',
            this.replaceList,
          );
        });
    },
    // TableFilter() {
    //   this.$store.commit(
    //     'setFilteredReplaceListToReplaceMnanagementPage',
    //     this.replaceList,
    //   );
    //   if (this.part_name != '') {
    //     this.$store.commit(
    //       'setFilteredReplaceListToReplaceMnanagementPage',
    //       this.filteredReplaceList.filter(x => x.part_name == this.part_name),
    //     );
    //   }
    //   if (this.machine_name != '') {
    //     this.$store.commit(
    //       'setFilteredReplaceListToReplaceMnanagementPage',
    //       this.filteredReplaceList.filter(
    //         x => x.machine_name == this.machine_name,
    //       ),
    //     );
    //   }
    // },
  },
  async created() {
    if (this.machines.length == 0) {
      await this.$store.dispatch('FETCH_MACHINE');
    }
    if (this.partsList.length == 0) {
      await this.$store.dispatch('FETCH_PARTS');
    }
    if (this.replaceList.length == 0) {
      await this.$store.dispatch('FETCH_PARTS_REPLACE', this.machines);
    }
    await this.$store.commit(
      'setFilteredReplaceListToReplaceMnanagementPage',
      this.replaceList,
    );
  },
};
</script>

<style></style>
