<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <div class="input_search">
            <input
              type="text"
              :value="searchText"
              placeholder="부품 검색"
              @input="setSearchText($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
          <div class="input_search">
            <input
              type="text"
              :value="locationText"
              placeholder="연동설비 검색"
              @input="setLocationText($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <h6>조회수 : {{ filteredReplaceLists.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>부품명</th>
              <th>연동설비명</th>
              <th>설비기기위치</th>
              <th>교체&점검주기(시간)</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(replacement, index) in filteredReplaceLists"
              :key="(replacement.id, index)"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
            >
              <td>
                {{ replacement.name }}
              </td>
              <td>
                {{ replacement.machine_name }}
              </td>
              <td>
                {{ replacement.machine_location }}
              </td>
              <td>{{ replacement.life_time }}</td>
              <td v-show="managementMode">
                <button
                  class="tbl_delete_btn"
                  @click="ShowModal(replacement.id)"
                >
                  delete
                </button>
              </td>
            </tr>
            <tr
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="5" @click="SelectRow(-1)">
                + 신규 부품 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-show="managementMode">
      <div class="head">
        <h5>{{ selectedIndex == -1 ? '신규 등록' : '내용 수정' }}</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label class="require">부품명</label>
            <my-local-selectric
              :id="'part_selectric'"
              :watch="manageData.part_id"
              :options="partOptions"
              @changeValue="$event => pushPart($event)"
            ></my-local-selectric>
          </div>
          <div class="input_text">
            <label class="require">연동설비명</label>
            <my-local-selectric
              :id="'machine_selectric'"
              :watch="manageData.machine_id"
              :options="ChainedMachineOptions"
              @changeValue="$event => pushMachine($event)"
            ></my-local-selectric>
          </div>
          <div class="input_text">
            <label class="require">설비기기 위치</label>
            <input
              v-model="manageData.machine_location"
              type="text"
              placeholder="설비위치 명칭"
              disabled
            />
          </div>
          <div class="input_text">
            <label class="require">교체&점검주기</label>
            <input
              v-model="manageData.life_time"
              type="text"
              placeholder="교체&점검주기"
            />
          </div>
          <button
            class="btn_sub2"
            type="button"
            @click="saveData"
            :disabled="checkValidate"
          >
            {{ selectedIndex == -1 ? '등록' : '수정' }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_isp($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
export default {
  components: {
    MyLocalSelectric,
    TwoButtonModal,
  },
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  data() {
    return {
      selectricOptions: [{ label: '선택', value: null, detail: '' }],
      target: null,
      manageData: {
        id: -1,
        part_id: '',
        machine_id: '',
        machine_location: '',
        life_time: '',
        change_dt: '',
      },
      selected_data: [],
      searchText: '',
      locationText: '',
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromLifetimeManagementPage',
      selectedIndex: 'getSelectedIndexFrompartsReplaeManagementForm',
      ChainedMachineOptions: 'getChainedMachinesForSelectric',
      partOptions: 'getPartsForSelectric',
      machines: 'getMachines',
      partsList: 'getPartsListFrompartsReplaeManagementForm',
      replaceList: 'getReplaceListFrompartsReplaeManagementForm',
      filteredReplaceList:
        'getFilteredReplaceListFrompartsReplaeManagementForm',
    }),
    filteredReplaceLists() {
      if (this.filteredReplaceList !== undefined) {
        const Hangul = require('hangul-js');
        return this.filteredReplaceList
          .filter(
            x =>
              x.name.includes(this.searchText) ||
              Hangul.d(x.name, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText),
          )
          .filter(
            x =>
              x.machine_name.includes(this.locationText) ||
              Hangul.d(x.machine_name, true)
                .map(x => x[0])
                .join('')
                .includes(this.locationText),
          );
      } else {
        return [];
      }
    },
    checkValidate() {
      if (this.managementMode) {
        let origin = this.selected_data;
        let modify = this.manageData;
        if (JSON.stringify(origin) === JSON.stringify(modify)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  async created() {
    if (this.machines.length == 0) {
      await this.$store.dispatch('FETCH_MACHINE');
    }
    if (this.partsList.length == 0) {
      await this.$store.dispatch('FETCH_PARTS');
    }
    if (this.replaceList.length == 0) {
      await this.$store.dispatch('FETCH_PARTS_REPLACE', this.machines);
    }
    await this.$store.commit(
      'setFilteredReplaceListToReplaceMnanagementPage',
      this.replaceList.filter(x => x.life_time != null),
    );
  },
  methods: {
    setSearchText(e) {
      this.searchText = e.target.value;
    },
    setLocationText(e) {
      this.locationText = e.target.value;
    },
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    async saveData() {
      if (
        this.manageData.machine_id == '=' ||
        this.manageData.part_id == '' ||
        this.manageData.life_time == ''
      ) {
        this.openOneButtonModal(
          '저장 오류',
          '부품명, 연동설비명, 교체&점검주기는 필수 입력항목입니다.',
        );
        return;
      }
      this.manageData.id = this.replaceList.filter(
        x =>
          x.machine_id == this.manageData.machine_id &&
          x.part_id == this.manageData.part_id,
      )[0].id;
      await this.$store
        .dispatch('UPDATE_REPLACE_PART', this.manageData)
        .then(() => {
          this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
          this.manageData = {
            id: -1,
            part_id: '',
            machine_id: '',
            machine_location: '',
            life_time: '',
            change_dt: '',
          };
          this.$store.commit('setSelectedIndexTopartsReplaeManagementForm', -1);
        })
        .catch(() => {
          this.openOneButtonModal('저장 오류', '저장 중 오류 발생.');
        });
      await this.$store.dispatch('FETCH_PARTS_REPLACE', this.machines);
      await this.$store.commit(
        'setFilteredReplaceListToReplaceMnanagementPage',
        this.replaceList.filter(x => x.life_time != null),
      );
    },
    async delete_isp() {
      this.showSpinner();
      this.$store
        .dispatch('DELETE_REPLACE_PART', this.my_modal_index)
        .then(async () => {
          this.openOneButtonModal('삭제 성공', '성공적으로 삭제하였습니다.');
          await this.$store.dispatch('FETCH_PARTS_REPLACE', this.machines);
          await this.$store.commit(
            'setFilteredReplaceListToReplaceMnanagementPage',
            this.replaceList.filter(x => x.life_time != null),
          );
          this.manageData = {
            id: -1,
            part_id: '',
            machine_id: '',
            machine_location: '',
            life_time: '',
            change_dt: '',
          };
          this.$store.commit('setSelectedIndexTopartsReplaeManagementForm', -1);
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    pushMachine(id) {
      let machine = this.findInfoFromId(this.machines, id);
      this.manageData.machine_id = machine.id;
      this.manageData.machine_location = machine.machine_location;
    },
    async pushPart(id) {
      let part = this.findInfoFromId(this.partsList, id);
      await this.$store.commit(
        'setSelectedPartIdToPartsReplaceManagementForm',
        part.id,
      );
      this.manageData.part_id = part.id;
      this.manageData.machine_location = '';
      this.manageData.machine_id = '';
      this.manageData.life_time = '';
    },
    SelectRow(index) {
      //행 선택 메소드
      let manageData = {};
      if (index == -1 || this.filteredReplaceList.length == 0) {
        manageData = {
          id: -1,
          part_id: '',
          machine_id: '',
          machine_location: '',
          life_time: '',
          change_dt: '',
        };
        this.$store.commit('setSelectedIndexTopartsReplaeManagementForm', -1);
      } else {
        manageData = {
          id: this.filteredReplaceList[index].id,
          part_id: this.filteredReplaceList[index].part_id,
          machine_id: this.filteredReplaceList[index].machine_id,
          machine_location: this.filteredReplaceList[index].machine_location,
          life_time: this.filteredReplaceList[index].life_time,
          change_dt: this.filteredReplaceList[index].change_dt,
        };
        this.selected_data = this.lodash.clonedeep(manageData);
        this.$store.commit(
          'setSelectedIndexTopartsReplaeManagementForm',
          index,
        );
        this.$store.commit(
          'setSelectedPartIdToPartsReplaceManagementForm',
          this.filteredReplaceList[index].part_id,
        );
      }
      this.manageData = manageData;
    },
    TableFilter() {
      this.$store.commit(
        'setFilteredReplaceListToReplaceMnanagementPage',
        this.replaceList,
      );
      if (this.part_name != '') {
        this.$store.commit(
          'setFilteredReplaceListToReplaceMnanagementPage',
          this.filteredReplaceList.filter(x => x.part_name == this.part_name),
        );
      }
      if (this.machine_name != '') {
        this.$store.commit(
          'setFilteredReplaceListToReplaceMnanagementPage',
          this.filteredReplaceList.filter(
            x => x.machine_name == this.machine_name,
          ),
        );
      }
    },
  },
};
</script>

<style></style>
