<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <thead>
            <tr>
              <th>설비기기 명</th>
              <th>설비기기 위치</th>
              <th>일별 가동시간</th>
              <th v-show="managementMode">삭제</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(machine, index) in filterdMachines"
              :key="(machine.id, index)"
              @click="SelectRow(index)"
              :class="selectedIndex == index ? 'active' : ''"
              tabindex="0"
            >
              <td>
                {{ machine.name }}
              </td>
              <td>
                {{ machine.machine_location }}
              </td>
              <td>
                {{ machine.operation_time }}
              </td>
              <td v-show="managementMode">
                <button class="tbl_delete_btn" @click="ShowModal(index)">
                  delete
                </button>
              </td>
            </tr>
            <tr
              v-show="managementMode"
              :class="selectedIndex == -1 ? 'new' : 'new_disable'"
              class="newBtn"
            >
              <td colspan="4" @click="SelectRow(-1)">
                + 신규 등록
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-show="managementMode">
      <div class="head">
        <h5>{{ selectedIndex == -1 ? '신규 등록' : '내용 수정' }}</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label class="require">설비기기명</label>
            <my-local-selectric
              :id="'machine_selectric'"
              :watch="manageData.id"
              :options="
                selectedIndex == -1 ? filteredmachineOptions : machineOptions
              "
              @changeValue="$event => pushMachine($event)"
            ></my-local-selectric>
          </div>
          <div class="input_text">
            <label class="require">설비기기 위치</label>
            <input
              v-model="manageData.machine_location"
              type="text"
              placeholder="설비위치 명칭"
              disabled
            />
          </div>
          <div class="input_text">
            <label class="require">일별 가동시간</label>
            <input
              v-model="manageData.operation_time"
              type="text"
              placeholder="일별 가동시간"
            />
          </div>
          <button
            class="btn_sub2"
            type="button"
            @click="saveData"
            :disabled="checkValidate"
          >
            {{ selectedIndex == -1 ? '등록' : '수정' }}
          </button>
        </form>
      </div>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="CloseModal"
      @OnYesClick="delete_isp($event)"
    ></two-button-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SpinnerMixin from '@/mixins/spinner';
import ModalMixin from '@/mixins/modal';
import FETCH_MIXIN from '@/mixins/fetch';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  components: { MyLocalSelectric, TwoButtonModal },
  mixins: [ModalMixin, SpinnerMixin, FETCH_MIXIN],
  computed: {
    ...mapGetters({
      managementMode: 'getManagementModeFromLifetimeManagementPage',
      selectedIndex: 'getSelectedIndexFromOperationIfoForm',
      machineOptions: 'getMachinesForSelectric',
      machines: 'getMachines',
    }),
    filterdMachines() {
      return this.machines.filter(machine => machine.operation_time != null);
    },
    checkValidate() {
      if (this.managementMode) {
        let origin = this.selected_data;
        let modify = this.manageData;
        if (JSON.stringify(origin) === JSON.stringify(modify)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      target: '',
      manageData: {
        id: -1,
        name: '',
        machine_location: '',
        operation_time: '',
        detail: '',
        process_verification_id: 0,
        counter_value: [],
      },
      selected_row: -1,
      selected_data: [],
      filteredmachineOptions: [],
      //modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  methods: {
    ShowModal(index) {
      this.my_modal_index = index;
      this.my_modal_title = '경고';
      this.my_modal_content = '정말로 삭제하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    async delete_isp() {
      this.showSpinner();
      this.$store
        .dispatch('DELETE_OPERATION_TIME', this.my_modal_index)
        .then(async () => {
          this.openOneButtonModal('삭제 성공', '성공적으로 삭제하였습니다.');
          this.manageData = {
            id: -1,
            name: '',
            machine_location: '',
            operation_time: '',
            detail: '',
            process_verification_id: 0,
            counter_value: [],
          };
          this.$store.commit('setSelectedIndexToOperationIfoForm', -1);
          await this.FETCH('FETCH_MACHINE', '설비');
        })
        .catch(() => {
          this.openOneButtonModal('삭제 오류', '삭제 중 오류 발생.');
        })
        .finally(() => {
          this.hideSpinner();
          this.CloseModal();
        });

      this.hideSpinner();
    },
    pushMachine(id) {
      let machine = this.findInfoFromId(this.machines, id);
      console.log(machine);
      this.manageData = {
        id: machine.id,
        name: machine.name,
        machine_location: machine.machine_location,
        operation_time: machine.operation_time,
        detail: machine.detail,
        process_verification_id: machine.process_verification_id,
        counter_value: [],
      };
    },
    SelectRow(index) {
      //행 선택 메소드
      this.selected_row = index;
      let manageData = {};
      if (index == -1 || this.machines.length == 0) {
        manageData = {
          id: -1,
          name: '',
          machine_location: '',
          operation_time: '',
          detail: '',
          process_verification_id: 0,
          counter_value: [],
        };
        this.$store.commit('setSelectedIndexToOperationIfoForm', -1);
      } else {
        manageData = {
          id: this.machines[index].id,
          name: this.machines[index].name,
          machine_location: this.machines[index].machine_location,
          operation_time: this.machines[index].operation_time,
          detail: this.machines[index].detail,
          process_verification_id: this.machines[index].process_verification_id,
          counter_value: this.machines[index].counter_value,
        };
        this.$store.commit('setSelectedIndexToOperationIfoForm', index);
        this.selected_data = this.lodash.clonedeep(manageData);
      }
      this.manageData = manageData;
    },
    async saveData() {
      if (
        (this.manageData.id != -1 && this.manageData.id != null) ||
        this.manageData.operation_time != ''
      ) {
        await this.$store
          .dispatch('UPDATE_MACHINE', this.manageData)
          .then(() => {
            this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
            this.manageData = {
              id: -1,
              name: '',
              machine_location: '',
              operation_time: '',
              detail: '',
              process_verification_id: 0,
              counter_value: [],
            };
            this.$store.commit('setSelectedIndexToOperationIfoForm', -1);
          })
          .catch(() => {
            this.openOneButtonModal('저장 오류', '저장 중 오류 발생.');
          });
      } else {
        this.openOneButtonModal(
          '저장 오류',
          '설비기기, 위치, 일별가동시간은 필수 입력항목입니다.',
        );
        return;
      }
      await this.FETCH('FETCH_MACHINE', '설비');
    },
  },
  async created() {
    if (this.machines.length < 1) {
      await this.FETCH('FETCH_MACHINE', '설비');
    }
    this.filteredmachineOptions = this.machineOptions.filter(
      x => x.operation_time == null,
    );
  },
};
</script>

<style></style>
